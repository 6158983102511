import React, { PureComponent } from "react";
import Swiper from "react-id-swiper";
class InfoStripe extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const params = {
      spaceBetween: 0,
      //slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 6000
      }
    };
    return (
      <div
        className="infoStripe"
        style={{
          textAlign: "center",

          padding: "0.4rem 0",
          backgroundColor: this.props.bgColor,
          color: this.props.textColor
        }}
      >
        <Swiper {...params}>
          {this.props.data.messages.map((message, i) => {
            return (
              <div>
                <strong>
                  {message.value.url ? (
                    <a href={message.value.url}>{message.value.text}</a>
                  ) : (
                    <span>{message.value.text}</span>
                  )}
                </strong>
              </div>
            );
          })}
        </Swiper>
      </div>
    );
  }
}

export default InfoStripe;

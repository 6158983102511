import React, { PureComponent } from "react";
import Swiper from "react-id-swiper";

import { Parallax } from "react-parallax";

import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import "../styles/swiper.css";

import "../styles/cockpitComponents/heroSlider.css";
const ReactMarkdown = require("react-markdown/with-html");
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class HeroSlider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imageLoaded: false,
      isMobile: true,
      width: "100%",
      strength: 400
    };
  }

  componentDidMount() {
    if (this.props.data.settings.width === "full") {
      this.setState({
        width: "100%"
      });
    }
    if (this.props.data.settings.width === "large") {
      this.setState({
        width: "1280px"
      });
    }
    if (this.props.data.settings.width === "medium") {
      this.setState({
        width: "1024px"
      });
    }
    if (this.props.data.settings.width === "small") {
      this.setState({
        width: "640px"
      });
    }

    if (window.innerWidth > 640) {
      this.setState({
        isMobile: false
      });
    }

    if (window.innerWidth <= 640) {
      this.setState({
        isMobile: true
      });
    }

    const reportWindowSize = () => {
      if (window.innerWidth > 640) {
        this.setState({
          isMobile: false
        });
      }

      if (window.innerWidth <= 640) {
        this.setState({
          isMobile: true
        });
      }
    };

    window.addEventListener("resize", reportWindowSize);

    if (this.props.data.settings.para_strength) {
      this.setState({
        strength: this.props.data.settings.para_strength
      });
    }
  }

  render() {
    window.dispatchEvent(new Event("resize"));
    // var height = "75";
    //
    // if (this.props.data.settings.height) {
    //   height = this.props.data.settings.height;
    // }
    const params = {
      pagination: {
        el: this.props.data.settings.dots ? ".swiper-pagination" : null,
        type: "bullets",
        clickable: true
      },
      navigation: {
        nextEl: this.props.data.settings.arrows
          ? ".swiper-button-next.swiper-button-white"
          : null,
        prevEl: this.props.data.settings.arrows
          ? ".swiper-button-prev.swiper-button-white"
          : null
      },
      spaceBetween: 0,

      //slidesPerView: 1,
      loop: false,
      autoplay: {
        delay: 6000
      },
      effect: this.props.data.settings.fade ? "fade" : null
    };
    // const cockpitImg = id => {
    //   var image = new Image();
    //   if (!this.state.imageLoaded) {
    //     image.onload = () => {
    //       this.setState({
    //         imageLoaded: true
    //       });
    //     };
    //   }
    //   image.src = `http://servito.badbyte.ch/cms/api/cockpit/image?token=f0e6a30ec4069ea95e02e8c8b2ea8c&src=${id}&w=1920&h=1080&q=80&o=true`;
    //   return image.src;
    // };

    const cockpitImg = id => {
      var image = new Image();
      if (!this.state.imageLoaded) {
        image.onload = () => {
          this.setState({
            imageLoaded: true
          });
        };
      }
      image.src = `http://seven.badbyte.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${id}&w=1920&h=1280&q=70&o=true`;
      return image.src;
    };
    return (
      <div style={{ backgroundColor: this.props.bgColor }}>
        <div
          style={{
            maxWidth: this.state.width,
            width: "100%",
            margin: "0 auto"
          }}
        >
          <ConditionalWrapper
            condition={this.props.data.settings.images.length > 1}
            wrapper={children => <Swiper {...params}>{children}</Swiper>}
          >
            {this.props.data.settings.images.map((item, i) => {
              return (
                <div key={i}>
                  <ConditionalWrapper
                    condition={
                      item.value.url && item.value.url.indexOf("/") === 0
                    }
                    wrapper={children => (
                      <Link
                        className="sliderLink"
                        to={
                          !this.props.locale
                            ? item.value.url
                            : `/${this.props.locale}${item.value.url}`
                        }
                        title={item.value.description}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    <ConditionalWrapper
                      condition={
                        item.value.url && item.value.url.indexOf("/") !== 0
                      }
                      wrapper={children => (
                        <a
                          className="sliderLink"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={item.value.url}
                          title={item.value.description}
                        >
                          {children}
                        </a>
                      )}
                    >
                      <Parallax
                        strength={parseInt(this.state.strength)}
                        bgImage={
                          item.value.image
                            ? cockpitImg(item.value.image._id)
                            : null
                        }
                        alt={item.value.description}
                      >
                        <div
                          className="sliderBg"
                          style={{
                            height:
                              this.state.isMobile &&
                              this.props.data.settings.height_mobile
                                ? this.props.data.settings.height_mobile + "vh"
                                : !this.state.isMobile &&
                                  this.props.data.settings.height
                                ? this.props.data.settings.height + "vh"
                                : "75vh",

                            backgroundColor: !this.state.imageLoaded
                              ? this.props.bgColor
                              : item.value.text || item.value.image_overlay
                              ? "rgba(20, 20, 20, .5)"
                              : "rgba(20, 20, 20, 0)"
                          }}
                        >
                          {item.value.text || item.value.image_overlay ? (
                            <div className="sliderBgInner">
                              <div className="centered">
                                {item.value.image_overlay ? (
                                  <Fade bottom>
                                    <img
                                      src={`http://seven.badbyte.ch/admin/storage/uploads${
                                        item.value.image_overlay.path
                                      }`}
                                      alt={item.value.description}
                                    />
                                  </Fade>
                                ) : null}
                                {item.value.text ? (
                                  <Fade bottom>
                                    <div
                                      className="sliderText"
                                      style={{
                                        color: this.props.data.settings
                                          .text_color
                                          ? this.props.data.settings.text_color
                                          : "#ffffff"
                                      }}
                                    >
                                      <ReactMarkdown
                                        source={item.value.text}
                                        escapeHtml={false}
                                      />
                                    </div>
                                  </Fade>
                                ) : null}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </Parallax>
                    </ConditionalWrapper>
                  </ConditionalWrapper>
                </div>
              );
            })}
          </ConditionalWrapper>
        </div>
      </div>
    );
  }
}

export default HeroSlider;

import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import ArticlesFeed from "../cockpitComponents/ArticlesFeed";
import TagManager from "react-gtm-module";

import "../styles/templates/articles.css";

class Articles extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    const response = await fetch(
      `http://seven.badbyte.ch/admin/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          filter: { published: true },
          lang: this.props.match.params.locale,
          sort: { date: -1 }
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });
      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "news"
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }
    return (
      <div className="articles">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "it"
              }
            />
            <meta charSet="utf-8" />
            <title>news - seven group</title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          <div className="articlesContainer">
            <ArticlesFeed
              data={this.state.data}
              limit={999}
              locale={this.props.match.params.locale}
            />
          </div>
        </Layout>
      </div>
    );
  }
}

export default Articles;

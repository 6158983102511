import React, { PureComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../Layout";
import TagManager from "react-gtm-module";

import "../styles/templates/article.css";
const ReactMarkdown = require("react-markdown/with-html");
class Article extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      data: []
    };
  }

  async fetchData() {
    // var locale = this.props.match.params.locale;
    // var filter;
    // if (locale === undefined) {
    //   filter = "title_slug";
    // } else {
    //   filter = `title_${locale}_slug`;
    // }

    const response = await fetch(
      `http://seven.badbyte.ch/admin/api/collections/get/blog`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          //filter: { [filter]: this.props.match.params.id },
          filter: { url_handle: this.props.match.params.id },
          lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      data: jsonData.entries[0]
    });
  }

  async componentDidMount() {
    window.scroll(0, 0);
    await this.setState({
      fetching: true
    });

    await this.fetchData();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      window.scroll(0, 0);
      await this.setState({
        fetching: true
      });

      await this.fetchData();

      this.setState({
        fetching: false
      });
    }
  }

  render() {
    var splittedDate;
    if (!this.state.fetching) {
      splittedDate = this.state.data.date.split("-");
      console.log(splittedDate);
    }

    if (!this.state.fetching) {
      const tagManagerArgs = {
        dataLayer: {
          page: "article " + this.state.data.title
        },
        dataLayerName: "PageDataLayer"
      };
      TagManager.dataLayer(tagManagerArgs);
    }

    return (
      <div className="article">
        {!this.state.fetching ? (
          <Helmet>
            <html
              lang={
                this.props.match.params.locale
                  ? this.props.match.params.locale
                  : "it"
              }
            />
            <meta charSet="utf-8" />
            <title>{this.state.data.title} - news - SEVEN GROUP</title>
          </Helmet>
        ) : null}
        <Layout
          url={this.props.match.url}
          locale={this.props.match.params.locale}
        >
          {!this.state.fetching ? (
            <div className="articleInner">
              <img
                className="articleImage"
                alt={this.state.data.title}
                src={`http://seven.badbyte.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                  this.state.data.image._id
                }&w=1400&h=600&q=50&o=true`}
              />
              <div className="articleContent">
                <h1 style={{ textAlign: "center" }}>{this.state.data.title}</h1>
                <div className="date">
                  {splittedDate[2]}-{splittedDate[1]}-{splittedDate[0]}
                </div>
                <ReactMarkdown
                  source={this.state.data.content}
                  escapeHtml={false}
                />
              </div>
            </div>
          ) : null}
        </Layout>
      </div>
    );
  }
}

export default Article;

import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SimpleMenuList from "../components/simpleMenuList";
import Drawer from "@material-ui/core/Drawer";
import DrawerList from "../components/drawerList";

import InfoStripe from "../cockpitComponents/InfoStripe";

import Button from "@material-ui/core/Button";

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: 12,
    marginRight: -12
  }
};

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true
    };
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      right: open
    });
  };

  async fetchHeader() {
    const response = await fetch(
      `http://seven.badbyte.ch/admin/api/singletons/get/header`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          lang: this.props.locale,
          populate: 6
          // fields: { name: 1, url_handle: 1 }
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    sessionStorage.setItem(
      `header_${this.props.locale}`,
      JSON.stringify(jsonData)
    );

    this.setState({
      header: jsonData
    });
  }

  async componentDidMount() {
    this.setState({
      fetching: true
    });
    const cachedHeader = sessionStorage.getItem(`header_${this.props.locale}`);

    if (cachedHeader) {
      this.setState({
        header: JSON.parse(cachedHeader),

        fetching: false
      });
    }
    await this.fetchHeader();

    this.setState({
      fetching: false
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.url !== prevProps.url) {
      this.setState({
        right: false
      });
      const cachedHeader = sessionStorage.getItem(
        `header_${this.props.locale}`
      );

      if (cachedHeader) {
        this.setState({
          header: JSON.parse(cachedHeader)
        });
      }
      await this.fetchHeader();
    }
  }

  render() {
    const { classes } = this.props;
    var currentUrl = this.props.url.split("/");
    console.log(currentUrl[1].length);
    if (currentUrl[1].length === 2) {
      currentUrl.splice(0, 2);
      currentUrl = currentUrl.join("/");
    } else {
      currentUrl.splice(0, 1);
      currentUrl = currentUrl.join("/");
      //currentUrl = this.props.url;
    }
    console.log(currentUrl);

    return (
      <div className="header">
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className="appbar"
            style={
              !this.state.fetching
                ? {
                    backgroundColor: this.state.header.bg_color,
                    color: this.state.header.text_color
                  }
                : null
            }
          >
            {!this.state.fetching &&
            this.state.header.info_stripe.messages.length >= 1 ? (
              <InfoStripe
                data={this.state.header.info_stripe}
                bgColor={this.state.header.info_stripe_bg}
                textColor={this.state.header.info_stripe_color}
              />
            ) : null}
            <Toolbar>
              <div className={classes.grow}>
                {!this.state.fetching ? (
                  <Link
                    to={!this.props.locale ? `/` : `/${this.props.locale}/`}
                    title="SEVEN GROUP"
                  >
                    <img
                      src={`http://seven.badbyte.ch/admin/storage/uploads${
                        this.state.header.logo.path
                      }`}
                      alt="SEVEN GROUP"
                      style={{ height: "56px", display: "block" }}
                    />
                  </Link>
                ) : null}
              </div>
              <div className="hidesmall">
                {!this.state.fetching
                  ? this.state.header.nav.map((item1, i1) => {
                      return item1.field.name === "parent" ? (
                        <SimpleMenuList
                          locale={this.props.locale}
                          key={i1}
                          menuLabel={item1.value.label}
                          menuItems={item1.value.links}
                          menuHandle={item1.value.handle}
                          bgColor={this.state.header.bg_color}
                        />
                      ) : item1.field.name === "page" ? (
                        <Button
                          color="inherit"
                          key={i1}
                          component={Link}
                          to={
                            !this.props.locale
                              ? `/${item1.value.link.url_handle}`
                              : `/${this.props.locale}/${
                                  item1.value.link.url_handle
                                }`
                          }
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item1.value.label
                            }}
                          />
                        </Button>
                      ) : item1.field.name === "custom" ? (
                        item1.value.url.indexOf("/") === 0 ? (
                          <Button
                            key={i1}
                            color="inherit"
                            component={Link}
                            to={
                              !this.props.locale
                                ? `${item1.value.url}`
                                : `/${this.props.locale}${item1.value.url}`
                            }
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item1.value.label
                              }}
                            />
                          </Button>
                        ) : (
                          <Button
                            key={i1}
                            color="inherit"
                            component="a"
                            target={item1.value.new_tab ? "_blank" : ""}
                            rel={
                              item1.value.new_tab ? "noopener noreferrer" : ""
                            }
                            href={item1.value.url}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: item1.value.label
                              }}
                            />
                          </Button>
                        )
                      ) : null;
                    })
                  : null}

                <div style={{ width: "2rem", display: "inline-block" }} />
                <Button
                  className={
                    this.props.locale === undefined
                      ? "langBtn selected"
                      : "langBtn"
                  }
                  key="Italiano"
                  color="inherit"
                  component={Link}
                  to={`/${currentUrl}`}
                  title="Italiano"
                  style={{ width: "1rem", display: "inline" }}
                >
                  IT
                </Button>
                <Button
                  className={
                    this.props.locale === "de" ? "langBtn selected" : "langBtn"
                  }
                  key="Deutsch"
                  color="inherit"
                  component={Link}
                  to={`/de/${currentUrl}`}
                  title="Deutsch"
                  style={{ width: "1rem", display: "inline" }}
                >
                  DE
                </Button>
                <Button
                  className={
                    this.props.locale === "en" ? "langBtn selected" : "langBtn"
                  }
                  key="English"
                  color="inherit"
                  component={Link}
                  to={`/en/${currentUrl}`}
                  title="English"
                  style={{ width: "1rem", display: "inline" }}
                >
                  EN
                </Button>
              </div>
              <div className="showsmall">
                <IconButton
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Menu"
                  onClick={this.toggleDrawer("right", true)}
                >
                  <MenuIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
        </div>
        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
        >
          {!this.state.fetching ? (
            <div
              tabIndex={0}
              style={{
                height: "100%",
                overflow: "auto",
                paddingBottom: "2rem",
                backgroundColor: this.state.header.sidebar_bg_color,
                color: this.state.header.sidebar_text_color
              }}
              role="button"
            >
              <DrawerList
                url={this.props.url}
                data={this.state.header.nav}
                blogInMenu={this.state.header.blog_in_menu}
                blogLabel={this.state.header.blog_label}
                locale={this.props.locale}
              />
            </div>
          ) : null}
        </Drawer>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);

import React, { PureComponent } from "react";
import Fade from "react-reveal/Fade";

import "../styles/cockpitComponents/gridList.css";

class GridGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      width: "1024px"
    };
  }

  toggleLightbox = i => {
    this.setState({
      lightboxIsOpen: true
      //src: src
    });
    //  setTimeout(function() {

    document.body.style.overflow = "hidden";
    setTimeout(function() {
      //  document.getElementById("lightBox").scrollTop = window.innerHeight * i;
      document.getElementById("lightBox").scroll({
        top: window.innerHeight * i,
        left: 0,
        behavior: "smooth"
      });
    });

    //  }, 100);
  };

  closeLightbox = src => {
    this.setState({
      lightboxIsOpen: false
    });
    document.body.style.overflow = "";
  };

  render() {
    if (this.props.data.settings.width === "full") {
      this.setState({
        width: "100%"
      });
    }

    if (this.props.data.settings.width === "large") {
      this.setState({
        width: "1280px"
      });
    }
    if (this.props.data.settings.width === "medium") {
      this.setState({
        width: "1024px"
      });
    }
    if (this.props.data.settings.width === "small") {
      this.setState({
        width: "640px"
      });
    }

    return (
      <div
        className="gridListContainer"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <Fade bottom cascade>
          <div
            className="gridList"
            style={{
              maxWidth: this.state.width,
              margin: "0 auto"
            }}
          >
            {this.props.data.settings.items.map((item, i) => {
              //console.log(item);
              return (
                <div
                  key={i}
                  className="gridListItem"
                  onClick={() => this.toggleLightbox(i)}
                >
                  <img
                    src={`http://seven.badbyte.ch/admin/api/cockpit/image?token=752611b463f91bb7434bacf241650d&src=${
                      item.value._id
                    }&w=640&h=640&q=50&o=true`}
                    alt={item.value.title}
                  />
                </div>
              );
            })}
          </div>
        </Fade>

        {this.state.lightboxIsOpen ? (
          <div
            className="lightBox"
            id="lightBox"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              height: "100vh",
              width: "100vw",
              zIndex: "10000",
              overflow: "auto",
              backgroundColor: "rgba(0,0,0,0.8)"
            }}
          >
            {this.props.data.settings.items.map((item, i) => {
              return (
                <div
                  style={{
                    padding: "1rem 0",
                    height: "100vh",
                    boxSizing: "border-box"
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      backgroundImage: `url(http://seven.badbyte.ch/admin/storage/uploads${
                        item.value.path
                      })`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center"
                    }}
                  />
                </div>
              );
            })}
            <div className="arrowTop" />
            <div className="arrowBot" />
            <div
              className="buttonCloseFull"
              onClick={() => this.closeLightbox()}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default GridGallery;

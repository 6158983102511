import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";
import "../styles/cockpitComponents/iconLinks.css";
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

class IconLinks extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //console.log(this.props.data);
    return (
      <div
        className="iconLinks"
        style={{
          backgroundColor: this.props.bgColor
        }}
      >
        <div>
          {this.props.data.settings.iconlinks.map((iconlink, i) => {
            return iconlink.value.image ? (
              <ConditionalWrapper
                key={i}
                condition={iconlink.field.name === "fileset"}
                wrapper={children => (
                  <a
                    className="iconLink"
                    href={`http://seven.badbyte.ch/${iconlink.value.file}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={iconlink.value.label}
                  >
                    {children}
                  </a>
                )}
              >
                <ConditionalWrapper
                  key={i}
                  condition={
                    iconlink.field.name === "linkset" &&
                    iconlink.value.url.indexOf("/") !== 0
                  }
                  wrapper={children => (
                    <a
                      className="iconLink"
                      href={iconlink.value.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={iconlink.value.label}
                    >
                      {children}
                    </a>
                  )}
                >
                  <ConditionalWrapper
                    key={i}
                    condition={
                      iconlink.field.name === "linkset" &&
                      iconlink.value.url.indexOf("/") === 0
                    }
                    wrapper={children => (
                      <Link
                        className="iconLink"
                        to={
                          !this.props.locale
                            ? iconlink.value.url
                            : `/${this.props.locale}${iconlink.value.url}`
                        }
                        title={iconlink.value.label}
                      >
                        {children}
                      </Link>
                    )}
                  >
                    <Fade bottom>
                      <img
                        src={`http://seven.badbyte.ch/admin/storage/uploads${
                          iconlink.value.image.path
                        }`}
                        alt={iconlink.value.label ? iconlink.value.label : null}
                      />
                      {iconlink.value.label ? (
                        <span
                          style={{
                            color: this.props.textColor
                          }}
                        >
                          {iconlink.value.label}
                        </span>
                      ) : null}
                    </Fade>
                  </ConditionalWrapper>
                </ConditionalWrapper>
              </ConditionalWrapper>
            ) : null;
          })}
        </div>
      </div>
    );
  }
}

export default IconLinks;

import React, { PureComponent } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import "../styles/cockpitComponents/form.css";
const ReactMarkdown = require("react-markdown/with-html");

var mailObj = {};
class Form extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      success: false,
      error: false
    };
  }

  handleField = name => event => {
    event.preventDefault();

    mailObj[name] = event.target.value;
  };

  handleChange = name => event => {
    mailObj[name] = event.target.checked;
  };

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, "g"), replace);
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });

    let sendMail = await fetch(
      "http://seven.badbyte.ch/admin/api/forms/submit/" +
        this.props.data.settings.form_name,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "e9a764154266ec9a59b91348aa1966"
        },
        body: JSON.stringify({
          form: mailObj
        })
      }
    );

    if (!sendMail.ok) {
      this.setState({
        loading: false,
        submitted: true,
        error: true
      });
      return;
    }

    this.setState({
      loading: false,
      submitted: true,
      success: true
    });

    // window.scroll(0, 0);
  }
  componentDidMount() {}

  render() {
    console.log(this.props.url);
    const StyledTextField = styled(TextField)`
      label {
        color: ${this.props.textColor};
        opacity: 1;
      }
      label.focused {
        color: ${this.props.textColor};
        opacity: 1;
      }
      .MuiInputBase-input {
        color: ${this.props.textColor};
      }
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${this.props.textColor};
          opacity: 0.6;
          color: ${this.props.textColor};
        }
        &:hover fieldset {
          border-color: ${this.props.textColor};
          opacity: 1;
        }
        &.Mui-focused fieldset {
          color: ${this.props.textColor};
          opacity: 1;
        }
      }
    `;
    return (
      <div
        style={{
          backgroundColor: this.props.bgColor,
          color: this.props.textColor
        }}
      >
        {!this.state.submitted ? (
          <form className="form" onSubmit={this.handleSubmit.bind(this)}>
            <h2
              style={{
                color: this.props.titleColor
              }}
            >
              {this.props.data.settings.title}
            </h2>
            <div style={{ width: "100%" }}>
              <ReactMarkdown
                source={this.props.data.settings.description}
                escapeHtml={false}
              />
            </div>
            {this.props.data.settings.form.map((item, i) => {
              return item.value.fieldtype === "text" ||
                item.value.fieldtype === "" ? (
                <div key={i} className={`formItem s-x100 x${item.value.width}`}>
                  <StyledTextField
                    style={{
                      color: this.props.textColor
                    }}
                    className="textfield text"
                    required={item.value.required}
                    id={item.value.id}
                    name={item.value.name}
                    label={item.value.label}
                    onChange={this.handleField(item.value.name)}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              ) : item.value.fieldtype === "number" ? (
                <div key={i} className={`formItem s-x100 x${item.value.width}`}>
                  <StyledTextField
                    style={{
                      color: this.props.textColor
                    }}
                    className="textfield number"
                    type="number"
                    required={item.value.required}
                    id={item.value.id}
                    name={item.value.name}
                    label={item.value.label}
                    onChange={this.handleField(item.value.name)}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              ) : item.value.fieldtype === "email" ? (
                <div key={i} className={`formItem s-x100 x${item.value.width}`}>
                  <StyledTextField
                    style={{
                      color: this.props.textColor
                    }}
                    className="textfield email"
                    type="email"
                    required={item.value.required}
                    id={item.value.id}
                    name={item.value.name}
                    label={item.value.label}
                    onChange={this.handleField(item.value.name)}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              ) : item.value.fieldtype === "date" ? (
                <div key={i} className={`formItem s-x100 x${item.value.width}`}>
                  <StyledTextField
                    style={{
                      color: this.props.textColor
                    }}
                    className="textfield date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required={item.value.required}
                    id={item.value.id}
                    name={item.value.name}
                    label={item.value.label}
                    onChange={this.handleField(item.value.name)}
                    margin="normal"
                    variant="outlined"
                  />
                </div>
              ) : item.value.fieldtype === "textarea" ? (
                <div key={i} className={`formItem s-x100 x${item.value.width}`}>
                  <StyledTextField
                    style={{
                      color: this.props.textColor
                    }}
                    className="textfield textarea"
                    required={item.value.required}
                    id={item.value.id}
                    name={item.value.name}
                    label={item.value.label}
                    onChange={this.handleField(item.value.name)}
                    margin="normal"
                    variant="outlined"
                    multiline
                  />
                </div>
              ) : item.value.fieldtype === "checkbox" ? (
                <div key={i} className={`formItem s-x100 x${item.value.width}`}>
                  <input
                    required={item.value.required}
                    onChange={this.handleChange(item.value.name)}
                    type="checkbox"
                    id={item.value.id}
                    name={item.value.name}
                    className="checkbox"
                  />
                  <label
                    style={{
                      paddingLeft: "0rem"
                    }}
                    htmlFor={item.value.id}
                  >
                    <ReactMarkdown
                      source={item.value.label}
                      escapeHtml={false}
                    />
                  </label>
                </div>
              ) : item.value.fieldtype === "select" ? (
                <div key={i} className={`formItem s-x100 x${item.value.width}`}>
                  <StyledTextField
                    style={{
                      color: this.props.textColor
                    }}
                    className="textfield select"
                    select
                    required={item.value.required}
                    id={item.value.id}
                    name={item.value.name}
                    label={item.value.label}
                    onChange={this.handleField(item.value.name)}
                    margin="normal"
                    variant="outlined"
                    color="inherit"
                    SelectProps={{
                      native: true
                    }}
                  >
                    <option disabled selected />
                    {item.value.options.split(",").map((option, j) => {
                      return (
                        <option style={{ color: "#333" }} key={option}>
                          {option}
                        </option>
                      );
                    })}
                  </StyledTextField>
                </div>
              ) : null;
            })}

            <div className="ctaContainer">
              <Button
                style={{
                  color: this.props.bgColor,
                  backgroundColor: this.props.textColor
                }}
                variant="contained"
                type="submit"
              >
                {this.props.data.settings.cta}
              </Button>
            </div>
          </form>
        ) : this.state.success ? (
          <div className="form">
            <ReactMarkdown
              source={this.props.data.settings.thankyou}
              escapeHtml={false}
            />

            {this.props.data.settings.enable_mailchimp ? (
              <form
                className="form"
                style={{ display: "block", width: "100%" }}
                action={this.props.data.settings.mailchimp.action_url}
                method="post"
                name="mc-embedded-subscribe-form"
              >
                <div style={{ display: "none" }}>
                  {this.props.data.settings.form.map((item, i) => {
                    return item.value.in_mailchimp ? (
                      <input
                        id={item.value.id}
                        name={item.value.name}
                        value={mailObj[item.value.name]}
                      />
                    ) : null;
                  })}
                </div>
                <div style={{ width: "100%" }}>
                  <input
                    required={true}
                    type="checkbox"
                    id="mailchimp_checkbox"
                    className="checkbox"
                  />

                  <label
                    style={{
                      paddingLeft: "1rem"
                    }}
                    htmlFor="mailchimp_checkbox"
                  >
                    {this.props.data.settings.mailchimp.label}
                  </label>
                </div>

                <div style={{ display: "none" }}>
                  <input
                    type="text"
                    id="MMERGE3"
                    name="MMERGE3"
                    value={this.props.pageId}
                  />
                  <input
                    type="checkbox"
                    value="1"
                    name="group[24645][1]"
                    id="mce-group[24645]-24645-0"
                    checked={this.props.locale == undefined ? "true" : ""}
                  />
                  <label for="mce-group[24645]-24645-0">IT</label>
                  <input
                    type="checkbox"
                    value="2"
                    name="group[24645][2]"
                    id="mce-group[24645]-24645-1"
                    checked={this.props.locale === "de" ? "true" : ""}
                  />
                  <label for="mce-group[24645]-24645-1">DE</label>
                  <input
                    type="checkbox"
                    value="4"
                    name="group[24645][4]"
                    id="mce-group[24645]-24645-2"
                    checked={this.props.locale === "en" ? "true" : ""}
                  />
                  <label for="mce-group[24645]-24645-2">EN</label>
                </div>

                <br />
                <br />
                <Button
                  style={{
                    color: this.props.bgColor,
                    backgroundColor: this.props.textColor
                  }}
                  variant="contained"
                  type="submit"
                >
                  {this.props.data.settings.mailchimp.cta}
                </Button>
              </form>
            ) : null}
          </div>
        ) : this.state.error ? (
          <div className="form">
            <h2 style={{ textAlign: "center" }}>Something went wrong :( </h2>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Form;

import React, { PureComponent } from "react";

import "./App.css";
import "./styles/antimess.css";
import Loading from "./components/loading";
import Home from "./templates/Home";
import Page from "./templates/Page";
import Article from "./templates/Article";
import Articles from "./templates/Articles";
import PrivacyPolicy from "./templates/PrivacyPolicy";
import CookiePolicy from "./templates/CookiePolicy";

import { Route, Switch } from "react-router-dom";

import TagManager from "react-gtm-module";

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { fetching: true, header: [], gtm: [] };
  }

  async fetchGtm() {
    const response = await fetch(
      `http://seven.badbyte.ch/admin/api/singletons/get/gtm`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "752611b463f91bb7434bacf241650d"
        },
        body: JSON.stringify({
          //lang: this.props.match.params.locale
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();
    this.setState({
      gtm: jsonData
    });

    this.tagManagerArgs = {
      gtmId: this.state.gtm.gtm_id,
      dataLayerName: "PageDataLayer"
    };

    TagManager.initialize(this.tagManagerArgs);
  }

  async fetchHeader() {
    const response = await fetch(
      `http://seven.badbyte.ch/admin/api/singletons/get/header`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "e9a764154266ec9a59b91348aa1966"
        },
        body: JSON.stringify({
          populate: 6
        })
      }
    );
    if (!response.ok) {
      return;
    }
    const jsonData = await response.json();

    this.setState({
      header: jsonData
    });
  }

  async componentDidMount() {
    this.setState({
      fetching: true
    });

    await this.fetchHeader();
    await this.fetchGtm();

    this.setState({
      fetching: false
    });
  }

  render() {
    return (
      <div className="App">
        {!this.state.fetching ? (
          <Switch>
            <Route path="/" exact strict component={Home} />
            <Route path="/:locale/" exact strict component={Home} />

            <Route
              path="/privacy-policy"
              exact
              strict
              component={PrivacyPolicy}
            />
            <Route
              path="/cookie-policy"
              exact
              strict
              component={CookiePolicy}
            />
            <Route
              path="/:locale/privacy-policy"
              exact
              strict
              component={PrivacyPolicy}
            />
            <Route
              path="/:locale/cookie-policy"
              exact
              strict
              component={CookiePolicy}
            />
            <Route path="/news" exact strict component={Articles} />
            <Route path="/:locale/news" exact strict component={Articles} />
            <Route path="/news/:id" exact strict component={Article} />
            <Route path="/:locale/news/:id" exact strict component={Article} />

            {this.state.header.nav.map((item1, i1) => {
              return item1.field.name === "parent" ? (
                <Route
                  key={i1}
                  exact
                  strict
                  path={`/${item1.value.handle}/:id`}
                  render={props => <Page {...props} />}
                />
              ) : item1.field.name === "page" ? (
                <Route
                  key={i1}
                  exact
                  strict
                  path={`/:id`}
                  render={props => <Page {...props} />}
                />
              ) : null;
            })}

            {this.state.header.nav.map((item1, i1) => {
              return item1.field.name === "parent" ? (
                <Route
                  key={i1}
                  exact
                  strict
                  path={`/:locale/${item1.value.handle}/:id`}
                  render={props => <Page {...props} />}
                />
              ) : item1.field.name === "page" ? (
                <Route
                  key={i1}
                  exact
                  strict
                  path={`/:locale/:id`}
                  render={props => <Page {...props} />}
                />
              ) : null;
            })}
          </Switch>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

export default App;
